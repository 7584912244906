<script setup lang="ts">
  import { toRef } from 'vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useNotification } from '../model/composables/use-notification';
  import type {
    AppNotificationPayload,
    GroupNotificationRecentNotifier,
  } from '../types';

  const props = defineProps<{
    notification: AppNotificationPayload;
  }>();

  const notification = toRef(props, 'notification');

  const {
    redirect,
    notifier,
    icon,
    timestamp,
    isUnread,
    isTypeFollower,
    isTypeMention,
    isTypeReply,
    isTypeReaction,
    notifiers,
    content,
    clampedHtml,
  } = useNotification(notification);

  const getUserAvatar = (
    user: GroupNotificationRecentNotifier,
  ): AppNotificationPayload => {
    const userAvatar = {
      ...notification.value,
      notifier: user.name,
    };
    if (user.nft?.length && user.nft[0]?.meta) {
      userAvatar.notifier_nft = user.nft;
    } else {
      userAvatar.notifier_icon_url = user.icon_url;
    }
    return userAvatar;
  };
</script>

<template>
  <section
    class="text-sm cursor-pointer hover:bg-white hover:bg-opacity-3 overflow-x-hidden"
    :class="{ 'bg-[#605BAC] bg-opacity-8': isUnread }"
    @click.prevent="redirect()"
  >
    <!-- Reaction & User Avatar Row -->
    <div class="flex py-2 px-6">
      <!-- Reaction Type -->
      <section class="flex flex-col items-center justify-center w-6 h-8 py-1">
        <base-icon
          :name="icon"
          size="w-[27px] h-[27px]"
          class="text-gray-500"
        />
      </section>
      <!-- Avatar -->
      <div class="isolate flex -space-x-1 overflow-hidden ml-4">
        <!-- Show single notifier for follower or mentions -->
        <template v-if="isTypeFollower || isTypeMention">
          <user-avatar
            :item="notification"
            size="w-8 h-8"
            class="flex-shrink-0"
          />
        </template>
        <!-- Show stacked avatar list for replies or reactions -->
        <template v-if="isTypeReply || isTypeReaction">
          <user-avatar
            v-for="(user, index) in notifiers?.slice(0, 3)"
            :key="index"
            :item="getUserAvatar(user)"
            :class="{
              'z-30': index === 0,
              'z-20': index === 1,
              'z-10': index === 2,
            }"
            size="w-8 h-8"
            class="relative inline-block"
          />
        </template>
      </div>
    </div>
    <!-- Username, Content & HTML -->
    <div class="py-2 px-6">
      <template v-if="isTypeFollower || isTypeMention">
        <div class="text-white">
          <base-button
            variant="custom"
            custom-classes="inline font-[500] hover:underline"
            @click.stop="redirect(notifier)"
          >
            {{ notifier }}
          </base-button>
          <span class="mx-1">
            {{ isTypeFollower ? 'followed you' : content }}
          </span>
          <span
            v-if="isTypeMention"
            class="inline-flex text-gray-400"
            v-html="clampedHtml"
          />
          <span class="text-gray-500">· {{ timestamp }}</span>
        </div>
      </template>
      <template v-if="isTypeReply || isTypeReaction">
        <template v-for="(user, index) in notifiers?.slice(0, 3)" :key="index">
          <base-button
            variant="custom"
            custom-classes="inline font-[500] hover:underline"
            @click.stop="redirect(user.name[0])"
          >
            {{ user.name[0] }}
          </base-button>
          <span v-if="index === notifiers!.length - 2 && notifiers!.length > 2">
            and
          </span>
          <span v-else-if="index < notifiers!.length - 2 && index !== 2"
            >,
          </span>
          <span
            v-else-if="index === notifiers!.length - 2 && notifiers!.length === 2"
          >
            and
          </span>
        </template>
        <span v-if="notifiers && notifiers.length > 3">
          and {{ notifiers!.length - 3 }}
          {{ notifiers!.length - 3 === 1 ? 'other' : 'others' }}</span
        >
        <span class="ml-1">
          {{ content }}
        </span>
        <!-- Post / Comment HTML -->
        <span class="inline-flex text-gray-400" v-html="clampedHtml" />
        <!-- Timestamp -->
        <span class="ml-1 text-gray-500">· {{ timestamp }}</span>
      </template>
    </div>
  </section>
</template>
