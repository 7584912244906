export default {
  about: '关于',
  add: '添加',
  addLink: '添加链接',
  addNewNft: '添加新的NFT',
  addRule: '添加规则',
  addWallet: '添加钱包',
  active: '积极的',
  all: '全部',
  allPeople: '所有人',
  allPortals: '所有门户',
  allVolume: '全卷',
  allowFrames: '允許幀',
  alsoCommented: '也评论了',
  and: '和',
  andOthers: '和别的',
  access: '使用權',
  accessDescription:
    '配對的錢包將顯示在此。 戰利品箱中的 Solana 戰利品將發送至您的主錢包。',
  appsAndSocial: '应用与社交',
  arcade: {
    creatorSpotlight: '创建者聚光灯',
    discoverGamingPortals: '发现游戏门户',
    enjoyGames: '享受免费游戏或赢得胜利',
    moreGamesComing: '更多游戏和社区排行榜即将推出。',
    newToArcade: '新的街机',
    owner: '所有者',
    slideTitle: '想提交您的游戏吗？',
    slidePrimaryButtonText: '在此申请',
    slideSecondaryButtonText: '了解更多',
    welcome: '欢迎来到DSCVR ARCADE',
  },
  auth: {
    subtitle: '突破 Web3 社群媒體的界限',
    detectionForm: {
      accountFound: '找到了！',
      yourAccount: '你的帳戶',
      wasMadeWith: '是用',
      useNonWalletBrowser:
        '請使用裝置上的非錢包瀏覽器（例如​​Chrome、Firefox或Safari）登入。',
      clickOneOfTheButtons: '點擊其中一個按鈕',
      clickTheButton: '點擊按鈕',
      belowToContinue: '下面繼續。',
      signInWith: '登陸使用',
      username: '用户名',
    },
    accountNotFound: {
      notFoundWithNFID: '找不到帐户。请确保您正在使用正确的NFID帐户',
      notFoundWithII: '找不到帐户。请确保您使用的是正确的Internet标识锚',
      signInWithUsername: '使用用户名登录',
    },
    mobileWalletApp: {
      title: '使用手机钱包应用程序',
      description: '请在您的手机钱包应用程序中登录 DSCVR',
    },
    loginForm: {
      byContinuing: '继续，即表示您同意我们的',
      termOfService: '服务条款',
      andOur: '和我们的',
      privacyPolicy: '隐私政策。',
    },
    registrationForm: {
      createAccount: '创建账户',
    },
  },
  back: '向后',
  backToProfile: '返回個人資料',
  backToFeeds: '返回飼料',
  backToPost: '返回帖子',
  balance: '平衡',
  ban: '禁止',
  banMember: '禁令成员',
  block: '堵塞',
  blocked: '梗阻',
  bookmarks: '书签',
  browseGames: '浏览游戏',
  canisterPrincipalId: '罐主ID',
  canisterIdErrorRequired: '需要ID',
  canisterIdErrorInvalid: '无效的罐头主ID',
  cancel: '取消',
  canvas: {
    errorLoading: '错误加载画布',
    emptyPostContentError: '帆布帖子内容不能为空',
    inEditorError: '帆布应用程序尚未发布',
    unknownMessageError: '画布：未知消息',
  },
  chain: '链',
  chainErrorRequired: '需要链',
  chooseYourPicture: '选择您的图片',
  claimed: '索取',
  claim: '要求',
  claiming: '自称',
  claimedDescription: '你已经领取了你的战利品',
  claimFive: '索取5點',
  claimHundred: '索取100點',
  claimLootbox: '领取战利品箱',
  close: '关闭',
  collab: '合作',
  collaborateOnAtlas3: '在ATLAS3上进行合作',
  collectionAddress: '收集地址',
  collectionAddressErrorRequired: '需要收集地址',
  collectionAddressErrorInvalid: '无效的收集地址',
  collectionAddressLegend: '请使用Magic Eden上可用的“链链收集”地址',
  collectionName: '收集名称',
  collectionNameErrorRequired: '需要收集名称',
  collectionErrorRequired: '需要收集',
  collections: '收藏',
  collectAirdrops: '收集免费的气水',
  comeBackIn: '回来吧',
  comments: '评论',
  connectWallet: '连接钱包',
  continue: '繼續',
  copied: '复制!',
  copiedToClipboard: '复制到剪贴板',
  copyPrincipal: '复制主ID',
  create: '创造',
  createAirdrop: '创建空投',
  createdBy: '由...制作',
  createNewRole: '创建新角色',
  createNftCollectionError: '存在一个问题，创建NFT门控',
  createNftCollectionSuccess: '启用了NFT门控',
  createPortal: '创建门户',
  createPost: '创建帖子',
  createRoleError: '创造角色存在问题',
  createRoleSuccess: '角色成功创造了',
  delete: '删除',
  deleteConfirmationMessage: '你確定要刪除這個嗎？',
  deleteNftCollectionError: '删除了NFT门控有问题',
  deleteNftCollectionSuccess: 'NFT门控成功删除了',
  deleteNftGate: '删除NFT门',
  day: '天',
  days: '天',
  description: '描述',
  disableComments: '禁用評論',
  dragImage: '將圖像拖放到此處上傳',
  dragMedia: '单击或拖动此处上传您的图像或视频.',
  dropFilesHere: '把你的文件放在這裡',
  dscvrdPoints: '看起來您已經DSCVRed了一些點數。',
  duration: '期间',
  earned: '赚到的',
  edit: '编辑',
  editProfile: '编辑个人资料',
  editProfilePhoto: '编辑个人资料照片',
  editRule: '编辑规则',
  embedImage: '嵌入图像',
  unpairedWalletNotification: '您還沒有添加錢包',
  error: '蹉',
  errorLoadingImage: '錯誤加載...',
  explore: '探索',
  exploreAndDiscover: '探索和发现',
  exploreMore: '探索更多',
  exploreSearchPlaceholderLarge: '与人互动并探索门户...',
  exploreSearchPlaceholderSmall: '找到您的社区...',
  favorites: '收藏夾',
  favoritePortals: {
    addToFavorites: '添加到收藏夾',
    applyChanges: '应用更改',
    favorite: '最喜欢的',
    favoritesEmptyLegend:
      '保存您最喜爱的门户以便在此处快速访问。现在就添加一些来扩展您的宇宙。',
    favoritesLegend: '保存您最喜爱的门户以便在此处快速访问',
    noResults: '没有结果',
    otherFavorites: '其他最爱',
    owned: '拥有',
    pleaseJoinToFavorite: '请加入最爱',
    removeFromFavorites: '从收藏夹中删除',
    reorder: '重新订购',
    topFavorited: '最喜欢的',
  },
  favoritesEmptyLegend:
    '保存您喜欢的门户以便在此处快速访问。现在添加一些来扩展宇宙。',
  favoritesLegend: 'SAve您喜欢的门户以便在此处快速访问',
  featured: '特色',
  featuredPortals: '特色门户',
  floorPrice: '地板价格',
  follower: '追随者',
  followers: '追随者',
  following: '下列的',
  frame: {
    address: '地址',
    buttonIndex: '按钮索引',
    commentsMaxReached: '评论必须小于1000个字符',
    debug: '调试',
    errorPost: '执行帧发布时出错',
    flagFrame: '标志框架',
    frameAction: '框架动作',
    frameIntroduction: '框架简介',
    frameLink: '框架链接',
    frameNotFoundError: '找不到框架',
    framesValidator: '帧验证器',
    frameUrl: '框架URL',
    frameUrlErrorRequired: '需要框架URL',
    inputText: '输入文本',
    maxResponseTimeLegend:
      '请求花费了5秒以上。这可能是正常的：第一个请求将需要更长的开发，但是在生产中，客户将超时请求5秒钟后',
    openFramesSpec: '打开帧规格',
    postId: '发帖ID',
    postIdErrorInvalid: '无效的发布ID',
    request: '要求',
    response: '回复',
    specs: '眼镜',
    state: '状态',
    times: '时代',
    timestamp: '时间戳',
    transactionId: '事务ID',
    trustedMessage: '值得信赖的消息',
  },
  gallery: '画廊',
  getNFT: '得到NFT',
  gift: '禮物',
  gimluck: {
    banner: {
      title: '和 Gimluck 一起试试你的运气吧！',
      description: '每天赢取奖品。',
      button: '宣称',
    },
    dialogs: {
      spinner: {
        title: '金拉克来了。快快抓住你的财富吧！',
        description: '如果奖品相符，Gimluck 就会送给您一份礼物。',
        button: '看看我的财运',
      },
      winner: {
        title: '这是你的幸运日！',
        description: {
          youWon: '你赢了',
          points: 'DSCVR 点',
        },
      },
      loser: {
        title: '你今天没有获胜，但智慧本身就是奖励。',
        content: '金拉克发话了。',
      },
    },
    embedded: {
      receivedWisdom: '从 Gimluck 那里获得了智慧。',
      theGimluckBestowed: '吉姆拉克所赐予的',
      to: '到',
    },
  },
  gotToSettings: '前往设置',
  guest: '客人',
  guests: '嘉賓',
  hide: '隐藏',
  home: '家',
  hotSpots: '热点',
  howToSetupPhantom: '如何设置幻影钱包',
  installMetamaskTitle: '未检测到荟萃分子',
  installMetamaskLegend: '请解锁或安装metAmask',
  invalidImageUrlError: '请输入有效的图像URL',
  invalidUrlError: '這似乎不是有效的網址',
  invite: '邀请',
  inviteToPortal: '邀请到门户',
  itemId: '项目ID',
  join: '加入',
  joined: '加入',
  key: '钥匙',
  keyboardC: '评论选项卡',
  keyboardDown: '向下滚动',
  keyboardG: '画廊标签',
  keyboardL: '像一个帖子',
  keyboardLeft: '转到上一篇文章',
  keyboardR: '公开反应',
  keyboardRight: '转到下一篇文章',
  keyboardS: '切换快捷菜单',
  keyboardShortcuts: '键盘快捷键',
  keyboardUp: '向上滑动',
  kick: '踢',
  kickMember: '踢成员',
  lastUsed: '最後使用',
  learnMore: '了解更多',
  leave: '离开',
  leaveConfirm: '您確定要離開嗎？',
  leavePortal: '离开门户',
  leavePortalLegend: '您确定要离开这个门户吗？',
  linkWarning: '請僅使用“https：//”的鏈接',
  listedSupply: '列出 /供应',
  listeningNow: '現在聽',
  login: '登录',
  lootbox: {
    creatorLootboxFound: '发现创作者战利品箱',
    criteria: '标准',
    claimSubtitle: {
      sm: '您的宝藏正在等',
      md: '您的宝藏正在等待。从打开的框中索取其余的战利品',
    },
    claimTitle: {
      sm: '无人认领的战利品',
      md: '您有无人认领的战利品',
    },
    claimYourLoot: {
      sm: '宣称',
      md: '要求您的战利品',
    },
    luckyYouCreator: '幸运的！您发现了一个创造者战利品箱',
    loot: '抢劫',
    lootbox: '赃物箱',
    lootEarned: '战利品获得了',
    mustBeMemberOf: '必须是以下组织的成员',
    mustFollow: '必须遵循',
    pairError: '您必须配对索拉纳钱包才能打开此赃物。',
  },
  luckyDay: '歡迎來到您的幸運日！',
  makeCoHost: '成為聯合主持人',
  managed: '管理',
  manageWallets: '管理錢包',
  markAsNSFW: '標記為NSFW',
  managePortals: '管理门户',
  marketplace: '市场',
  marketplaceCollectionsLegend:
    '潜入nfts的宝库抢夺。一些收藏品是您解锁此惊人门户网站的黄金钥匙。 🔑✨',
  marketplaceCollectionItemLegend: '解锁此门户。 🔑✨',
  matureContent: '成人內容 (NSFW)',
  member: '成员',
  members: '成员',
  memberSince: '从那以后',
  more: '更多的',
  mustJoinPortalMessage: '您必须加入门户以查看此内容',
  myProfile: '我的简历',
  newRoleName: '新角色名称',
  network: '网络',
  nextPage: '下一页',
  nftAirdrop: {
    setAsPFP: '设置为PFP',
    viewOnMarketplace: '在Entrepot上查看',
  },
  nftCollection: 'NFT收集',
  nftGate: 'NFT门',
  nftGates: 'NFT大门',
  nftGatesLegend: 'NFT Gate您的门户网站，根据NFT所有权赋予您的会员角色。',
  nftHolderRole: 'NFT持有人的角色',
  nftTransfer: 'NFT转移',
  nftType: 'NFT类型',
  nftTypeErrorRequired: '需要NFT类型',
  noCommentsYet: '暂时没有评论',
  noGalleryItems: '还没有画廊物品',
  noLanguage: '您尚未設置翻譯語言！',
  noRecentPortal: '沒有最近的傳送門',
  notifications: {
    title: '通知',
    portalAdmin: '門戶管理員',
    empty: {
      title: '保持最新動態',
      excerpt: '當有人喜歡、評論或分享您的貼文時，不要錯過任何一個節拍。',
    },
    options: {
      title: '選項',
      grouping: {
        title: '群組通知',
        subtitle: '將高頻通知合併到分組訊息中。',
      },
    },
    preferences: {
      title: '優先',
      commentsAndReplies: '評論和回复',
      newFollowers: '新的追蹤者',
      likesAndReactions: '喜歡和反應',
      mentions: '提及',
      streakReminders: '透過電子郵件進行連續提醒',
    },
    content: {
      postMention: '在貼文中提到你',
      commentMention: '在留言裡提到了你',
      postReply: '回覆了你的貼文',
      postMediaReply: '回覆了您的媒體貼文',
      commentReply: '回覆了你的評論',
      postReaction: '對你的貼文做出了反應',
      postMediaReaction: '對您的媒體貼文作出反應',
      commentReaction: '對你的評論做出了反應',
    },
    inAppTitle: '我想收到',
    markAllAsRead: '標記為已讀',
    settings: {
      streakRemindersErrorTitle: '請驗證您的電子郵件。',
    },
    viewAllNotifications: '查看所有通知',
  },
  nsfwTooltipContent: '仅适用于成熟观众和潜在的 NSFW',
  onboarding: {
    introductory: {
      welcome: '欢迎',
      title: '完成入职以获得奖励',
      description:
        '我们知道入职可能会很乏味。为了弥补这一点，如果您完成所有步骤，我们将赠送您以下礼物。',
      rewards: {
        title: '赚取奖励',
        description:
          '完成入职即可赚取您的第一个 DSCVR 积分，这是 DSCVR 的社交货币，可用于创建您的第一个社区。',
      },
      lootbox: {
        title: '获得免费的战利品箱',
        description:
          'Lootbox 包含来自最著名的加密原生项目和社区的免费 NFT、代币和其他数字资产。',
      },
    },
    interest: {
      title: '你对什么感兴趣呢？',
      description: '选择 3 个或更多主题来帮助我们策划您的 Feed',
      noneOfThese: '都不是',
    },
    discoverUsers: {
      title: '发现热门用户',
      description: '关注我们最受欢迎的用户以了解最新动态并加入对话。',
      follow: '跟随',
    },
    joinPortals: {
      title: '加入门户',
      description: '我们找到了一些符合您兴趣的门户网站。',
      descriptionNoInterests: '查看我们最活跃、最具吸引力的门户网站。',
      join: '加入',
    },
    missingSteps: {
      title: '完成入职申请',
      user: {
        title: '至少关注三个人',
        description: '与其他用户联系是 DSCVR 的核心部分。',
      },
      portal: {
        title: '加入至少三个门户',
        description: '寻找社区是 DSCVR 变得有趣和有趣的一部分。',
      },
      completeOnboarding: '完成入职',
    },
    rewards: {
      title: '只需注册即可获得 50 DSCVR 积分！',
      description:
        '通过声称您的每日连续记录、发布获得点赞的内容以及推荐朋友来累积 DSCVR 积分。 DSCVR 积分可以让您有资格获得战利品箱并访问特殊门户。',
      claimNow: '现在宣称',
      claimed: '声称！',
    },
    nft: {
      title: '您获得了独家 NFT！',
      subtitle: '设置为您的个人资料图片并赚取 50 分。',
      description:
        '您的 NFT 正在前往您的钱包。如果网络状况繁忙，这可能需要长达一个小时。如果您将 NFT 设置为您的 PFP，您将获得 50 DSCVR 积分奖励。',
      viewMyWallet: '查看我的钱包',
    },
    connectYourWallet: {
      title: '连接你的钱包',
      description: '为了领取这种独特的 NFT，您必须连接一个钱包。',
      connectWallet: '连接钱包',
      error: '连接您的钱包时出错',
    },
    whatIsInTheBox: '盒子里是什么？！',
    unlock: '开锁',
    unlockLootbox: '解锁战利品箱',
    claim: {
      tooltipContent: '争取你的第一次连胜，赚取更多积分！',
    },
    newUser: '新用户将获得独家战利品！',
    chooseAtLeast3: '至少选择 3 个',
    skip: 'Skip',
  },
  open: '打开',
  opened: '已经开放',
  opening: '开辟',
  pair: '一对',
  paired: '配对',
  people: '人们',
  pickPortal: '選擇門戶',
  play: '玩',
  points: '点',
  pointsAdded: '我們已經將額外的5點添加到您的帳戶中。',
  pointsReceived: '收到的點數',
  pollEnptyQuestionError: '問題不能為空',
  pollMinQuestionsError: '您必須至少有 2 個投票問題',
  pollQuestionMaxCharsError: '問題不能超過 120 個字符',
  pollRequiredVotingPeriodError: '您必須設置投票長度週期。',
  popularPortals: '流行门户',
  portal: '门户网站',
  portalAdmin: '門戶管理員',
  portalDoNotExist: '该门户不存在',
  portalError: '在發布之前選擇一個入口',
  portalInvite: {
    buildCommunity:
      '建立您的社区。 复制推荐链接并下载图像以邀请朋友访问您的门户。',
    buildProfile:
      '建立您的社区。 复制推荐链接并下载图像以邀请朋友关注您的个人资料。',
    inviteFriends: '邀请你的朋友',
    joinPortal: '加入此门户',
    followUser: '加入社区',
    invited: '你已被邀请',
    invitedToJoin: '您已被邀请加入',
    invalidReferralCode: '推荐代码无效',
    embraceFuture: '拥抱社交媒体的未来！ 参与为您构建的去中心化社区！',
  },
  portalPermissionError: '您無權在此門戶網站上發帖。',
  portalRestrictedSubtitle: '您无法访问此门户。',
  portalRestrictedNote:
    '如果您剛剛配對或購買了 NFT，則此入口網站可能需要幾分鐘才能解鎖。',
  portalRestrictedTitle: '获得NFT加入',
  portalNSFWRestrictedError: '在您的设置中启用NSFW查看此内容',
  post: '發布',
  postButtonLabelGift: '礼物',
  postButtonLabelReply: '回复',
  postButtonLabelRepost: '重新发布',
  postButtonLabelShare: '分享',
  postButtonLabelToken: '尖端',
  postCreated: '您的帖子已成功创建',
  postDeletedConfirm: '您的未保存的帖子將被刪除。',
  postError: '在發布之前輸入帖子內容',
  postFeature: {
    commented: '评论',
    errorDelete: '删除此帖子时出错，请重试',
    errorRemove: '删除此帖子时出错，请重试',
    interacted: '互动',
    originalPostDoNotExist: '原帖子已不存在，但您可以查看其他热门帖子。',
    postDoNotExist: '该帖子不存在',
    postRestricted: '您没有权限查看此帖子。',
    replied: '回复了',
  },
  postUpdated: '您的帖子已成功更新',
  posts: '帖子',
  postTo: '發佈到',
  prevPage: '上一页',
  primaryChain: '主要链',
  primaryWallet: '基本的',
  principalId: '主要ID',
  purchased: '购买',
  raisedHands: '舉手',
  readMore: '阅读更多',
  reason: '原因',
  recent: '最近的',
  recommended: '受到推崇的',
  referral: '推荐',
  referrals: '推荐人',
  referralFeature: {
    community: {
      referrals: {
        capacitor: '电容器',
        copyReferralLink: '复制推荐链接',
        description:
          '想要被动积分吗？推荐用户每赚取 10 点即可获得 1 点奖励积分。另外，赚取 100 点奖励积分即可为您的电容器充电，以获得空投倍增器！',
        empty: '空的',
        title: '你的电容没电了！',
      },
    },
    getRewards: '获得奖励！',
    inviteYourFriends: '邀请你的朋友',
    multipliers: '乘数',
    multiplier: '乘数',
    onboarding: {
      title: '连接你的朋友，获得奖励',
      bonus: {
        title: '被动接收奖励积分',
        description: '您的推荐人每赚取 10 点积分，即可获得 1 点奖励积分。',
      },
      airdrop: {
        title: '最大化您的空投乘数',
        description: '通过推荐每获得 100 点奖励积分，您将获得空投乘数。',
      },
    },
    tooltipContent: '在此向您的朋友发送邀请链接',
    copyLink: '链接已复制到剪贴板',
  },
  remove: '消除',
  removeConfirmationMessage: '您確定要刪除它嗎？',
  removeLink: '删除链接',
  removeLinkLegend: '您确定要删除此链接吗？',
  removeRule: '删除规则',
  removeRuleLegend: '您确定要删除此规则吗？',
  repliedTo: '回复了',
  reply: '回覆',
  report: '報告',
  requiredUrlError: '你需要一個網址',
  requiresFifty: '需要：50',
  roleErrorRequired: '需要角色',
  roleNameErrorDuplicated: '角色名称已经存在',
  roleNameErrorLength: '角色名称必须在1到20个字符之间',
  roleNameErrorRequired: '需要角色名称',
  rollTheDice: '掷骰子！',
  ruleTitle: '规则标题',
  ruleDescription: '规则说明',
  runtime: '運行',
  scrollAndDiscover: '滚动并发现',
  search: '搜索',
  searchNftCollection: '搜索NFT集合',
  searchPortals: '搜索門戶',
  selectLanguage: '選擇翻譯語言',
  selectPrimaryChain: '選擇主鏈',
  selectSiteLanguage: '語言',
  selectSitePortalSorting: '門戶分類',
  selectSitePrimaryLanguage: '選擇主要語言',
  selectSiteTranslatedLanguage: '翻譯',
  selectSiteTranslatedPrimaryLanguage: '選擇翻譯',
  send: '发送',
  sendGift: '送礼物？',
  sendGiftLegend: '您确定您想赠送这个NFT吗？此操作无法撤消。',
  setLanguage: '設定翻譯語言',
  userSettings: {
    address: '地址',
    avatarRequirements: '至少280 x 280 px',
    coverPhotoRequirements: '至少1088x300 PX',
    errorUploadingFile: 'Có một lỗi khi tải lên tệp',
    invalidIcpNftAddressMessage: '只能使用有效的IC NFT',
    invalidImageFileMessage: 'Vui lòng tải lên tệp hình ảnh',
    updateProfileError: '更新个人资料有错误',
    username: '用户名',
    userProfile: '用户资料',
  },
  setupNftWatcher: '启用NFT门控',
  setupNftWatcherSuccess: '启用了NFT门控',
  share: '分享',
  sharePortal: '共享门户',
  shareProfile: '共享个人资料',
  show: '展示',
  showcaseNFTs: '展示您的Solana NFT画廊',
  showContent: '展示內容',
  showInMarketplace: '在市场上展示',
  signup: '报名',
  signUp: {
    thrilledRegistration:
      '我们很高兴你选择加入我们创新思想和创作者的社区！只剩最后一步了。',
    alreadyHaveAccount: '已有账号？',
    dontHaveAccount: '还没有帐户吗？',
  },
  sort: '种类',
  speaker: '揚聲器',
  speakers: '揚聲器',
  spotlight: '聚光灯',
  status: '地位',
  streak: {
    airdropMultiplier: '空投倍数',
    earned: '赢得了',
    highest: '最高',
    highestStreak: '最高条纹',
    streak: '条纹',
    totalEarned: '总赚取',
    informationDialog: {
      title: '增加获得独家空投的机会!',
      lightning: {
        title: '增加连胜的机会',
        description: '登录并点击领取按钮即可增加您在动态中接收独家空投的机会。',
      },
      plus: {
        title: '每天登录即可赚取 DSCVR 积分',
        description:
          '开始收集你的积分。您连续击中的次数越多，您获得的积分就越多。',
      },
    },
    shareDialog: {
      title: {
        global: '噗！您已经充值了空投倍数！你已经赚到了',
      },
      lightning: {
        title: '继续前进并赚取下一个乘数（它们可以叠加）',
        description: '登录并点击领取按钮即可增加您在动态中接收独家空投的机会。',
      },
      plus: {
        title: '每天登录即可赚取 DSCVR 积分',
        description:
          '开始收集你的积分。您连续击中的次数越多，您获得的积分就越多。',
      },
      shareYourStreak: '分享你的连胜记录',
      unlockedAn: '解锁了一个',
    },
    buttonLabel: '领取您的积分',
    duringAStreakOf: '在连续的',
    activeButton: {
      tooltipContent: '选择此按钮即可领取您的积分！',
    },
    activeDialog: {
      allPoweredUp: '全部通电！',
      longStreaksEarnMore: '长条纹收入更多',
      powerUp: '启动你的空投计！',
      referFriend: '介绍个朋友',
      toClaimNextReward: '领取下一个奖励',
      unbrokenStreak: '不间断的连胜可以获得更多积分！',
      unlockedAn: '解锁了一个',
      wantMorePoints: '想赚取更多积分吗？',
      youAreOnAStreak: '你正处于连胜之中！',
      getNotified: '收到通知',
      optInToReceive: '選擇接收電子郵件提醒',
      optedIn: '選擇加入',
      toast: {
        title: '您將收到通知！',
        description: '您已選擇透過電子郵件接收連勝提醒。 存取設定以更改權限。',
        gotoSettings: '前往設定',
      },
    },
  },
  stay: '停留',
  submit: '提交',
  takeMeToTheUnknown: '带我去未知的',
  titleError: '在發布之前輸入標題',
  to: '对',
  tokens: '令牌',
  tokenTransfer: {
    amountErrorMessage: '数量必须比0大',
    balanceErrorMessage: '没有足够的平衡。',
    invalidWalletErrorMessage: '无效的IC钱包本金',
    invalidWalletExtErrorMessage: '无效的IC钱包地址或本金',
    reviewTransfer: '审查转移',
    title: 'NFT转移',
    transferNftFailedMessage: 'NFT转移交易失败',
    transferTokenFailedMessage: '令牌传输交易失败',
    transferNftLegend: '请确认您即将发送NFT',
    transferTokenLegend: '请确认您即将发送',
    transferWarning:
      '所有交易都是不可逆的，因此请在继续之前确保上述详细信息正确。',
    transferNftSuccessMessage: 'NFT转移成功了',
    transferTokenSuccessMessage: '令牌转移成功了',
    validationNftLegend: '输入您希望将此NFT转移到的Internet计算机钱包地址。',
    validationTokenLegend: '输入您希望传输此代币的Internet计算机钱包地址。',
    walletPlaceholder: 'IC钱包本金',
    walletPlaceholderExt: 'IC钱包本金或地址',
  },
  tip: {
    contentOwnerHasNoWalletTitle: '内容所有者没有连接钱包',
    contentOwnerHasNoWalletDescription:
      '内容所有者没有连接钱包，因此无法接收小费。请尝试与其他人分享。',
    connectWallet: '连接钱包以发送小费',
    signatureFailed: '签名失败',
    preparingTipTitle: '准备小费',
    preparingTipDescription: '我们正在准备您的小费。请稍等片刻，然后再试一次。',
    noWalletConnectedTitle: '没有钱包连接',
    noWalletConnectedDescription:
      '您必须连接一个钱包才能发送小费。请连接一个钱包，然后再试一次。',
    successTitle: '小费成功',
    successDescription: '您的小费已成功发送。',
  },
  top: '顶部',
  transfer: '转移',
  translate: '翻譯',
  unblock: '除去障碍',
  unpair: '不归',
  unsavedChanges: '刪除未保存的更改？',
  update: 'Update',
  updateNftCollectionError: '在更新NFT门控时存在问题',
  updateNftCollectionSuccess: 'NFT门控成功更新了',
  upload: '上传',
  uploadImage: '上传图片',
  uploadProfilePhoto: '上传个人资料照片',
  uploadCoverPhoto: '上传封面照片',
  useNFT: '使用NFT',
  user: '用户',
  userBlocked: '被阻止',
  userDoNotExist: '该用户不存在',
  userNoLongerBlocked: '不再被阻止',
  userNoLongerFollowsYou: '不再跟随你',
  verifiedSolanaPFPs: '已验证的Solana PFP',
  verifyPhone: '验证电话',
  verifyPhoneLegend: '您必须有经过验证的电话才能加入此门户',
  view: '看法',
  viewAll: '查看全部',
  viewOnMagicEden: '关于魔术伊甸园的查看',
  verifyingAtDSCVR: '在DSCVR上进行验证',
  voteCommentPermissionError: '您无权投票此评论',
  voteCommentError: '有错误投票此评论',
  waiting: '等待',
  wallet: '钱包',
  wallets: {
    connectWallet: {
      connectAWallet: '连接钱包',
      connecting: '连接',
      errorConnectingWallet: '连接钱包的错误',
      installed: '已安装',
      invalidWalletConnection: '无效的钱包连接',
      mobileConnectError: '钱包不能在手机上连接',
      detected: '检测到',
      notDetected: 'Not detected',
      popular: '受欢迎的',
    },
    evm: {
      providersNotFound: '无法找到以太货币提供者',
    },
    sendingToWallet: {
      title: '我们正在将您发送至您的钱包',
      description:
        '完成后返回 DSCVR。如果您没有收到确认消息，请重新连接您的钱包。',
      takeMeToMyWallet: '带我去钱包',
    },
    successfullyPaired: {
      successfullyConnected: '连接成功！',
      takeMeToMyDscvrWallet: '带我到我的 DSCVR 钱包',
    },
    unsuccessfulPairing: {
      inApp: {
        pairingUnsuccessful: '配對失敗',
        pairThisAccount: '改為配對此帳戶',
        useDifferentWallet: '使用不同的錢包',
        walletAlreadyPairedTo: '該錢包已配對',
        pairingWillUnpairFrom: '配對您的錢包將會取消配對',
      },
      identity: {
        walletAlreadyPaired: '錢包已配對',
        walletAlreadyAssociated: '該錢包關聯於 ',
        signinWithThisAccount: '使用此帳號登入',
      },
    },
    addAnotherWallet: '添加另一个钱包',
    addressCopied: '地址复制',
    allWallets: '所有钱包',
    failedToLoad: '无法加载...',
    mobileWalletPairing: '移动钱包配对',
    linkWalletFailed: '連結錢包失敗',
    linkWalletFailedDescription: '地址連結錢包失敗：',
    thanksForPairing: '感谢您的配对，您现在可以返回到您的移动浏览器',
    address: '地址',
    refreshWallets: '重新整理',
    sharingError: '无法分享令牌',
    showAllAssets: '显示所有资产',
    verifyNftPfpError: '验证NFT时出错',
    matrica: {
      walletsRefreshedTitle: '我们已经刷新并检索了您的Matrica钱包。',
      walletsRefreshedDescription: '您可以返回到您的移动浏览器。',
      refreshWalletsErrorTitle: 'Matrica刷新钱包错误',
      refreshWalletsErrorDescription:
        '我们无法刷新您的Matrica钱包。请再试一次。',
      refreshWalletsSuccessDescription:
        '您的Matrica钱包已成功刷新。您可以返回到您的移动浏览器。',
      linkWalletLinkErrorTitle: 'Matrica链接钱包链接错误',
      linkWalletLinkErrorDescription:
        '我们无法链接您的Matrica钱包。请再试一次。',
      linkWalletUnlinkErrorTitle: 'Matrica链接钱包取消链接错误',
      linkWalletUnlinkErrorDescription:
        '我们无法取消链接您的Matrica钱包。请再试一次。',
      redirectErrorMessage: '我们无法将您重定向到Matrica钱包。请再试一次。',
      linkedText: '取消链接',
      unlinkedText: '链接',
    },
  },
  walletTransaction: {
    commentsErrorRequired: '您必须输入评论',
    confirm: '确认',
    confirmTransaction: '确认交易',
    great: '伟大的！',
    loadingTransactionMessage: '在扩展中确认连接。',
    maliciousReports: '恶意报告',
    missingConnectedWalletError: '找不到连接的钱包',
    opening: '开场',
    proceedAnyway: '仍要继续',
    reason: '原因',
    reasonFraud: '欺诈罪',
    reasonSpam: '垃圾邮件',
    reasonOther: '其他',
    reasonErrorRequired: '请从列表中选择一个原因',
    redirectMessageTitle: '离开 DSCVR',
    redirectMessageDescription: '您即将离开 DSCVR，请单击“继续”继续。',
    report: '报告',
    reportTransaction: '报告交易',
    sendingTransaction: '发送交易',
    transactionGenericError: '错误处理交易',
    transactionNotFoundError:
      '交易成功，但区块链的时间安排了。请检查交易ID以验证。这可能需要几分钟的时间',
    transactionId: '事务ID',
    transactions: '交易',
    transactionSuccessMessage: '这是怎么走的？',
    transactionSuccessTitle: '交易成功！',
    transactionReportedTitle: '据报道这项交易',
    transactionReportedMessage:
      '该交易已被其他用户标记为潜在的恶意。DSCVR建议取消此交易。',
    transactionReportTitle: '报告交易',
    transactionWarning: '交易警告',
  },
  twitter: {
    oauthError: '哎呀！链接您的 X 帐户时出现问题。请稍后再试',
    unlinkError: '哎呀！取消链接您的 X 帐户时出现问题。请稍后再试',
    unpairedSocialPairingText: '配对您的 X 帐户以将用户名添加到您的个人资料中',
    oauthSuccess: '您的 X 帐户已成功链接',
    unlinkSuccess: '您的 X 帐户已成功取消链接',
  },
  nftMarketplace: {
    buy: '购买',
    buyNow: '立即购买',
    price: '价格',
    sold: '已售出',
    royalty: '版税',
    takerFee: '接受者费用',
    tensor: { viewOnTensor: '在张量上查看', buyOnTensor: '在张量上购买' },
    magicEden: {
      viewOnMagicEden: '在魔术伊甸园上查看',
      buyOnMagicEden: '在魔术伊甸园上购买',
    },
  },
  youMightLike: '你可能会喜欢',
  verification: {
    title: '獲得證實',
    subtitle:
      '我們將向您發送一封驗證電子郵件。 點擊電子郵件中的連結並返回 DSCVR。',
    resendTitle: '發送！',
    resendSubtitle:
      '驗證您的電子郵件後返回 DSCVR。 若要選擇接收條紋通知，請按一下條紋圖示並選擇「取得通知」。',
    email: {
      form: {
        errors: {
          invalid: '請輸入有效電子郵件',
          required: '電子郵件地址為必填項',
        },
      },
      buttons: {
        sendEmail: '發電子郵件',
        verify: '核實',
        sent: '傳送',
        resend: '重發',
        verified: '已驗證',
      },
      resendExcerpt:
        '可能需要幾分鐘才能到達您的電子郵件。 檢查垃圾郵件以防萬一。',
      notifications: {
        successTitle: '您已被驗證！',
        successDescription: '感謝您驗證您的電子郵件。',
        errorTitle: '驗證電子郵件時發生錯誤',
        errorDescription:
          '請務必使用最新的驗證電子郵件或重新傳送新的電子郵件。',
      },
      accountEmailUpdated: '電子郵件已成功更新',
      emailSuccessfullySent: '驗證郵件已發送',
      emailNotSent: '發送驗證郵件失敗：',
      errors: {
        tooManyRequests: '請求太多',
        unknownError: '未知错误',
      },
    },
  },
  redirecting: '重定向',
  welcome: '欢迎',
};
