import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  useFormatHTMLContent,
  useFormatMarkdownContent,
  useHtmlClamp,
  useIsolateHtmlParts,
} from '@/composables';
import { useNotificationStore } from '@/entities/notification';
import { fromNow } from '@/shared/lib';
import { useNotificationSettingStore } from '@/entities/user';
import { POST_DETAIL_COMMENT_NO_TITLE, USER_CONTENT } from '@/common';
import type { Ref } from 'vue';
import { type ReactionType, reactionTypeToIconName } from '@/entities/reaction';
import type {
  AppNotificationPayload,
  ContentMention,
  ContentReply,
  Reaction,
} from 'dfx/edge/edge.did';
import { storeToRefs } from 'pinia';

export const useNotification = (notification: Ref<AppNotificationPayload>) => {
  const router = useRouter();
  const { t } = useI18n();
  const notificationStore = useNotificationStore();
  const notificationSettingStore = useNotificationSettingStore();
  const { groupNotifications } = storeToRefs(notificationSettingStore);

  const redirect = (username?: string) => {
    if (username && typeof username === 'string') {
      router.push({
        name: USER_CONTENT,
        params: { username },
      });
    } else {
      const entries = Object.entries(notification.value.notification_type);
      const notificationType = entries[0][0];
      const notificationContent = entries[0][1];
      if (
        [
          'CommentMention',
          'PostMention',
          'PostReaction',
          'CommentReaction',
        ].includes(notificationType)
      ) {
        const { root_parent_id, content_id } = notificationContent as
          | ContentMention
          | Reaction;
        router.push({
          name: POST_DETAIL_COMMENT_NO_TITLE,
          params: {
            id: root_parent_id[0]?.toString(),
            comment: content_id.toString(),
          },
        });
      }
      if (['PostReply', 'CommentReply'].includes(notificationType)) {
        const { root_parent_id, comment_id } =
          notificationContent as ContentReply;
        router.push({
          name: POST_DETAIL_COMMENT_NO_TITLE,
          params: {
            id: root_parent_id[0]?.toString(),
            comment: comment_id.toString(),
          },
        });
      }
      if (['Follower'].includes(notificationType)) {
        router.push({
          name: USER_CONTENT,
          params: { username: notification.value.notifier[0] },
        });
      }
    }
    notificationStore.closeDrawer();
  };

  const notificationType = computed(() => {
    for (const key in notification.value.notification_type) {
      return key;
    }
    return null;
  });

  const notifier = computed(() => {
    const { notifier } = notification.value;
    if (notifier.length > 0) {
      return notifier[0];
    }
    return undefined;
  });

  const icon = computed(() => {
    const notificationTypeEntries = Object.entries(
      notification.value.notification_type,
    );
    let reaction: ReactionType = 'like';
    if (
      ['PostReaction', 'CommentReaction'].includes(
        notificationTypeEntries[0][0],
      )
    ) {
      reaction = Object.keys(
        (notificationTypeEntries[0][1] as Reaction).reaction,
      )[0].toLowerCase() as ReactionType;
    }

    const iconMap = {
      CommentMention: 'at-sign',
      PostMention: 'at-sign',
      CommentReply: 'filled-chat',
      PostReply: 'filled-chat',
      Follower: 'profile-add',
      CommentReaction: reactionTypeToIconName(reaction),
      PostReaction: reactionTypeToIconName(reaction),
    };
    return iconMap[notificationTypeEntries[0][0] as keyof typeof iconMap];
  });

  const timestamp = computed(() => {
    const timestamp = fromNow(notification.value.created_at, true);
    const indexOfAgo = timestamp?.indexOf(' ago');
    if (indexOfAgo !== -1) {
      return timestamp?.slice(0, indexOfAgo);
    }
    return timestamp;
  });

  const isUnread = computed(() => {
    return (
      'read_at' in notification.value && notification.value.read_at.length <= 0
    );
  });

  const isTypeMention = computed(() => {
    return ['CommentMention', 'PostMention'].includes(notificationType.value!);
  });

  const isTypeReply = computed(() => {
    return ['CommentReply', 'PostReply'].includes(notificationType.value!);
  });

  const isTypeReaction = computed(() => {
    return ['CommentReaction', 'PostReaction'].includes(
      notificationType.value!,
    );
  });

  const isTypeFollower = computed(() => {
    return ['Follower'].includes(notificationType.value!);
  });

  /**
   * Notification Grouping
   */
  const notifiers = computed(() => {
    return notification.value?.notification_group?.[0]?.recent_notifiers?.[0];
  });
  // TODO: Identify with BE team why body is empty
  const body = ref(notification.value.body[0] || '');
  const { isHTML, computedBody } = useFormatHTMLContent(body);
  const { compiledMarkdownAsHTML } = useFormatMarkdownContent();
  const finalBody = computed(() => {
    if (isHTML.value) {
      return computedBody.value;
    }
    return compiledMarkdownAsHTML(computedBody.value);
  });
  const { text } = useIsolateHtmlParts(finalBody);
  const options = computed(() => ({
    maxLineBreaks: 1,
    maxCharacters: 100,
  }));
  const { clampedHtml } = useHtmlClamp(text, options);

  const content = computed(() => {
    switch (notificationType.value!.toLowerCase()) {
      case 'postmention':
        if (groupNotifications.value) {
          return `${t('notifications.content.postMention')}: `;
        } else {
          return `${t('notifications.content.postMention')}`;
        }
      case 'commentmention':
        if (groupNotifications.value) {
          return `${t('notifications.content.commentMention')}: `;
        } else {
          return `${t('notifications.content.commentMention')}`;
        }
      case 'postreply':
        if (groupNotifications.value) {
          if (finalBody.value.includes('video')) {
            return `${t('notifications.content.postMediaReply')}.`;
          }
          if (finalBody.value.includes('preview')) {
            return `${t('notifications.content.postReply')}.`;
          }
          return `${t('notifications.content.postReply')}: `;
        } else {
          return `${t('notifications.content.postReply')}`;
        }
      case 'commentreply':
        if (groupNotifications.value) {
          return `${t('notifications.content.commentReply')}: `;
        } else {
          return `${t('notifications.content.commentReply')}`;
        }
      case 'postreaction':
        if (groupNotifications.value) {
          if (finalBody.value.includes('video')) {
            return `${t('notifications.content.postMediaReaction')}.`;
          }
          if (finalBody.value.includes('preview')) {
            return `${t('notifications.content.postReaction')}.`;
          }
          return `${t('notifications.content.postReaction')}: `;
        } else {
          return `${t('notifications.content.postReaction')}`;
        }
      case 'commentreaction':
        if (groupNotifications.value) {
          return `${t('notifications.content.commentReaction')}: `;
        } else {
          return `${t('notifications.content.commentReaction')}`;
        }
      default:
        return '';
    }
  });

  return {
    redirect,
    notificationType,
    notifier,
    icon,
    timestamp,
    isUnread,
    isTypeMention,
    isTypeReply,
    isTypeReaction,
    isTypeFollower,
    notifiers,
    content,
    clampedHtml,
  };
};
