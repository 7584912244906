import { Principal } from '@dfinity/principal';
import {
  FOLLOW,
  HOME,
  PORTAL_CONTENT,
  PORTALS,
  SEARCH,
  USER_CONTENT,
  ALL,
} from './route-names';

export const DSCVR_STATIC_ASSETS_CDN_URL = `https://fe-assets.dscvr.one/images`;

export const COVER_PHOTOS = [
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_1.jpg`,
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_2.jpg`,
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_3.jpg`,
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_4.jpg`,
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_5.jpg`,
  `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/gradient_6.jpg`,
];

export const DSCVR_OG_DROP_BASE_URL =
  'https://s6hwe-laaaa-aaaab-qaeba-cai.raw.ic0.app';

export const NFT_SAURON_PRINCIPAL = Principal.fromText(
  'nxuzg-jolox-tq4fq-sgyxv-rds43-l7or5-pwwhs-bzpka-dr6ro-axqzo-hqe',
);
export const NFT_SAURON_ROLE = 'NFT Watcher';
export const NFT_TYPE_EXT = 'ext';

export const SOCIAL_NETWORKS = {
  atlas3: {
    icon: 'social-atlas3',
    iconDesaturated: 'social-atlas3-desaturated',
    matches: ['https://atlas3.io/project/'],
  },
  discord: {
    icon: 'social-discord',
    iconDesaturated: 'social-discord-desaturated',
    matches: ['https://discord', 'https://www.discord'],
  },
  instagram: {
    icon: 'social-instagram',
    iconDesaturated: 'social-instagram-desaturated',
    matches: ['https://instagram', 'https://www.instagram'],
  },
  facebook: {
    icon: 'social-facebook',
    iconDesaturated: 'social-facebook-desaturated',
    matches: ['https://facebook'],
  },
  linkedin: {
    icon: 'social-linkedin',
    iconDesaturated: 'social-linkedIn-desaturated',
    matches: ['https://www.linkedin.com', 'https://linkedin.com'],
  },
  openChat: {
    icon: 'social-open-chat',
    iconDesaturated: 'social-open-chat-desaturated',
    matches: ['https://oc.app'],
  },
  telegram: {
    icon: 'social-telegram',
    iconDesaturated: 'social-telegram-desaturated',
    matches: ['https://telegram', 'https://t.me'],
  },
  tikTok: {
    icon: 'social-tiktok',
    iconDesaturated: 'social-tiktok-desaturated',
    matches: ['https://tiktok', 'https://www.tiktok'],
  },
  twitch: {
    icon: 'social-twitch',
    iconDesaturated: 'social-twitch-desaturated',
    matches: ['https://twitch'],
  },
  twitter: {
    icon: 'social-twitter',
    iconDesaturated: 'social-twitter-desaturated',
    matches: [
      'https://twitter',
      'https://www.twitter',
      'https://x',
      'https://www.x',
    ],
  },
  youTube: {
    icon: 'social-youtube',
    iconDesaturated: 'social-youtube-desaturated',
    matches: [
      'https://youtube',
      'https://youtu.be',
      'https://www.youtube',
      'https://www.youtu.be',
    ],
  },
  genericWebsite: {
    icon: 'social-web',
    iconDesaturated: 'social-web-desaturated',
    matches: [],
  },
};

export const IS_HIDDEN = true;

export const ADMIN_USER_TYPE = 2;

export const POST_FILE_MAX_UPLOAD_SIZE_MB = 20;
export const POST_IMAGE_ACCEPTED_MIME_TYPES = [
  'image/webp',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/x-png',
];
export const POST_MEDIA_ACCEPTED_MIME_TYPES = [
  ...POST_IMAGE_ACCEPTED_MIME_TYPES,
  'video/mp4',
];
export const POST_FILE_MAX_UPLOAD_SIZE_ERROR_MESSAGE = `Files need to be in image or mp4 video format and less than ${POST_FILE_MAX_UPLOAD_SIZE_MB}mb in size.`;
export const ROLE_KIND_FIELD_EVERYONE = 'Everyone';
export const ROLE_KIND_FIELD_DEFAULT = 'Default';
export const ROLE_KIND_FIELD_CUSTOM = 'Custom';

export const DATE_RANGE = {
  HOUR: '3600',
  DAY: '86400',
  WEEK: '604800',
  MONTH: '2592000',
  YEAR: '31536000',
  ALL_TIME: '',
};
export const HTML_PREFIX = '‰HTML';

export const DOMAINS_BLACKLIST = [
  /^https:\/\/(www\.)?gaoding.com/,
  /^https:\/\/.*?.cdn.ampproject.org/,
  /^https:\/\/decrypt\.co\/cryptocom\/bitcoin/,
  /^https:\/\/newsbtc\.com/,
  /^https:\/\/(www\.)npr\.org/,
];

export const LITE_YOUTUBE_REGEX =
  /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:m\.)?(?:youtu(?:be)?\.com\/(?:v\/|embed\/|watch(?:\/|\?v=))|youtu\.be\/)((?:\w|-){11})(?:\S+)?$/;

export const VIDEO_STREAMING_DOMAINS = [
  LITE_YOUTUBE_REGEX,
  /^https:\/\/(www\.)?dailymotion?\.com/,
  /^https:\/\/(www\.)?(oembed\.)?vice\.com/,
  /^https:\/\/(www\.)?(amp\.)?cnbc\.com/,
  /^https:\/\/cdn.jwplayer.com/,
  /^https:\/\/streamin.me/,
  /^https:\/\/player.vimeo.com\/video/,
];

export const IFRAME_SANDBOX_RESTRICTIONS =
  'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation';
export const PROD_URL_100MS = 'https://prod-init.100ms.live/';

export const A_YEAR = 60 * 60 * 24 * 365;
export const A_DAY = 60 * 60 * 24;
export const A_WEEK = 7 * 24 * 60 * 60 * 1000 * 1000 * 1000;

export const RANDOM_GRADIENTS = [
  'bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500',
  'bg-gradient-to-br from-green-200 via-green-300 to-blue-500',
  'bg-gradient-to-br from-rose-500 via-pink-500 to-indigo-400',
  'bg-gradient-to-br from-blue-300 via-green-200 to-yellow-300',
  'bg-gradient-to-tl from-green-300 via-blue-500 to-purple-600',
  'bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500',
  'bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-green-300 to-purple-400',
  'bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-green-300 via-yellow-300 to-pink-300',
];

export const NOTIFICATIONS_PAGE_SIZE = BigInt(20);

// LAYOUTS
export const BLANK_LAYOUT = 'blank-layout';
export const DEFAULT_LAYOUT = 'default-layout';
export const PROFILE_LAYOUT = 'profile-layout';
export const SETTINGS_LAYOUT = 'settings-layout';
export const DEVELOPMENT_LAYOUT = 'development-layout';

export const PWA_HELP_URL =
  'https://www.notion.so/dscvr/How-to-install-the-DSCVR-PWA-on-iOS-Android-or-macOS-Desktop-36511a1da51b407b89e28982b20c935f';

export const DESCRIPTION_MAX_LENGTH = 257;
export const PHONE_VERIFICATION_REQUIRED_ERROR = 'Phone verification required';
export const FRAMES_SPEC_URL =
  'https://docs.farcaster.xyz/reference/frames/spec';
export const FRAME_EXAMPLES = [
  'https://dscvr-frame-getting-started.vercel.app/',
  'https://dscvr-frame-poll.vercel.app/?id=1',
  'https://dscvr-frame-carousel.vercel.app/',
  'https://dscvr-frame-quiz.vercel.app/?id=1',
  'https://frog.fm/',
  'https://framesjs.org/',
];
export const FRAMES_MAX_RESPONSE_TIME_MS = 5000;
export const FRAMES_CLIENT_PROTOCOL = 'dscvr@0.1.0';
export const SOLANA_FM_PREFIX = 'https://solana.fm/tx/';
export const TWITTER_AUTHORIZATION_URL =
  'https://twitter.com/i/oauth2/authorize';
export const TWITTER_OAUTH_SCOPES =
  'offline.access tweet.read tweet.write users.read follows.read follows.write';

// @see: https://analytics.google.com/analytics/web/#/a188673786p260824564/admin/streams/table/2273609069
export const GOOGLE_ANALYTICS_ID = 'G-31L75DSNYC';
// @see: https://docs.google.com/document/d/1-T0kAca6_swLd92iKn2OtWjw7VZmQPzjk8Yse44yUdc/edit?usp=sharing
export const GOOGLE_TAG_MANAGER_ID = 'GTM-WMQGQKF8';

export const FEED_PAGES = [
  ALL,
  FOLLOW,
  HOME,
  PORTAL_CONTENT,
  PORTALS,
  SEARCH,
  USER_CONTENT,
];
